import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as rdd from "react-device-detect";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppleAuth from "../../components/auth/AppleAuth";
import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import bgImage from "../../../assets/images/bottom-bg1.svg";
import kinoImg from "./kinoImage2.png";

import "./SignUp.css";
import { useLocation } from "react-router";
import VerifyEmail from "../../components/Modals/VerifyEmail";

const kinoTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    textAlign: "center",
    color: "#032836",
    fontSize: "2.5rem",
    
  },
  mainDescr: {
    textAlign: "center",
    color: "#032836",
    fontSize: ".9rem"
  },
  orangeTitle: {
    color: "#FF824D", 
  },
  socialNetworkTitle: {
    color: "#FF824D", 
    fontWeight: "600",
    margin: "1rem 0"
  },
  termsDescriptions: {
    width: "100%",
    marginTop: "5rem",
    color: "gray",
    fontWeight: "600",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  takeMeInTitle: {
    color: "#f29322",
    fontSize: "3rem",
    textAlign: "center",
    marginBottom: "5rem"
  },
  takeMeInDescription: {
    color: "#B8C1C5",
    fontSize: "1.7rem",
    textAlign: "center",
    marginBottom: "4rem"
  },
  signInButton: {
    backgroundColor: "#f29322",
    width: "100%",
    maxWidth: "200px",
    color: "#ffff",
    fontSize: "1rem !important",
    fontWeight: "900 !important",
    marginTop: "3rem",
    display: "flex",
    margin: "0 auto"
  }, 
  signUp: {
    backgroundColor: "#fefefa",
    border: "4px solid rgb(247, 165, 80)",
    borderRadius: "18px",
    padding: "2rem",
    margin: "0rem auto",
    maxWidth: "650px",
    position: "relative",
    height: "750px",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "0rem !important",
      marginBottom: "3rem",
    },
  },
  signUpContent: {
    maxWidth: "850px",
    margin: "0 auto",
  },
  paper: {
    maxWidth: "500px",
  },
  dialog: {
    backgroundColor: "#f8f8f8",
  },
  signupButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",

  },
  textField: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "2px",
      marginBottom: "2px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "3px",
      marginBottom: "3px",
    },
  },
  textFieldSm: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: "2px",
      marginBottom: "2px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "3px",
      marginBottom: "3px",
    },
  },
  textFieldCustom: {
    flex: 1,
  },
  title: {
    fontSize: "3.5rem",
    textAlign: "center",
    color: "var(--darkColor)",
    lineHeight: "1.2",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  },
  titleSm: {
    fontSize: "2.2rem",
    textAlign: "center",
    fontWeight: 500,
    color: "var(--darkColor)",
    fontWeight: "500",
    width: "85%",
    margin: "auto",
    marginBottom: "2rem",
    marginTop: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      marginTop: "2rem",
    },
  },
  terms: {
    paddingBottom: "0.3rem",
    marginLeft: "-1.2rem",
    maxWidth: "100%",
    lineHeight: "1.5rem",
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "var(--darkColor)",
    color: "var(--grey)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "var(--darkColor)",
      boxShadow: "none",
    },
    marginTop: "1rem",
    maxWidth: "250px",
    

  },
  link: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
    color: "#032836",
    marginTop: "2rem",
    fontSize: "1.2rem !important",
  },
  formError: {
    fontSize: "14px",
    color: "red",
    paddingLeft: "8px",
    "&::before": {
      content: "⚠ ",
    },
  },
  sign: {
    color: "var(--darkColor)",
    fontSize: "1rem",
    fontWeight: 400,
  },
  mainError: {
    textAlign: "center",
    color: "red",
    fontWeight: "400",
  },
  alternateAuth: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    padding: "8px",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0 !important",
    },
  },
  kinoAuth: {
    fontSize: "18px",
    margin: "1.5rem 8px",
    color: "#032836",
    textAlign: "center",
    borderBottom: "1px solid #808080",
    lineHeight: "0rem",
  },
  kinoAuthSpan: {
    backgroundColor: "#fff",
    padding: "0 1rem",
    whiteSpace: "nowrap",
  },
  errorGeneral: {
    padding: "10px",
    marginTop: "0",
    margin: "10px",
    backgroundColor: "#ff726f",
    color: "#fff",
    borderRadius: "4px",
    textAlign: "center",
  },
  form: {
    maxWidth: "500px",
    margin: "0 auto",
    marginTop: "3rem",
  },
  iconTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: "1rem",
    fontWeight: 600,
  },
  termsPolicy: {
    textAlign: "center",
    paddingTop: "8rem",
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingTop: "4rem",
    },
  },
  termsPolicyFull: {
    textAlign: "center",
    paddingTop: "4rem",
    fontWeight: 500,
    fontSize: "1rem",
  },
  ReCAPTCHA: {
    display: "block",
  },
  ReCAPTCHAContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    maxHeight: "7rem",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0px",
      maxHeight: "6rem",
    },
  },
  takeMeIn: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  }
}));

const SignUp = ({ ...rest }) => {
  const recaptchaRef = useRef();
  const [timeZone, setTimeZone] = useState();
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  const [loading, setLoading] = useState(false);
  const recaptchaSiteKey = "6LfshwcqAAAAAI21_Qpnp484psz_u8IRrytwMMlu";
  const [errorForm, setErrorForm] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [generalMsg, setGeneralMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signUpWithMail, setSignUpWithMail] = useState(false);
  let timer = null;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    // confirmPassword: "",
    promoCode: "",
    terms: "on",
  });

  const {
    firstName,
    lastName,
    email,
    password,
    // confirmPassword,
    promoCode,
    terms,
  } = formData;

  const { register, errors, handleSubmit, watch } = useForm({});
  const userPassword = useRef({});
  userPassword.current = watch("password", "");

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };

  const classes = useStyles();

  const handleRegisterClose = () => {
    rest.setRegisterOpen(false);
    recaptchaRef.current.reset();
    setErrorForm("");
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    rest.registerOpen === false && setLoading(false);
    daylights_savings();
  }, [rest.registerOpen]);

  const handleRecaptcha = (e) => {
    // console.log("Recaptcha change",e);
    setErrorForm("");
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
    name === "email" && setErrorForm("") && setLoading(false);
  };

  const daylights_savings = () => {
    let date = new Date();
    let offset = moment(date).isDST();
    if (offset === true) {
      setTimeZone(moment().utcOffset() - 60);
    } else {
      setTimeZone(moment().utcOffset());
    }
  };

  // const checkTimezone = () => {
  //   daylights_savings();
  //   console.log(timeZone);
  // }

  const onSubmit = async (e) => {
    const recaptchToken = recaptchaRef.current.getValue();
    if(!recaptchToken){
      setErrorForm("Please complete the reCAPTCHA verification");
      return
    }
    // console.log("Recaptcha token", recaptchToken);
    timer = setTimeout(() => {
      setLoading(false);
    }, 20000);

    setLoading(true);

    await axios
      .post(
        "/register",
        {
          name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          recaptcha_token: recaptchToken,
          // password_confirmation: confirmPassword,
          promo_code: promoCode,
          agree_terms: "on",
          utcOffset: timeZone,
          _token: token,
        },
        config
      )
      .then(
        (request) => {
          if (request.data.errors === undefined) {
            //success
            // console.log(request.data);
            // window.location.replace(`/email-unverified?email=${email}`);
            handleRegisterClose();
            rest.setVerifyModalOpen(true);
            rest.setVerifyEmail(email);
          } else {
            //something went wrong
            recaptchaRef.current.reset();
            clearTimeout(timer);
            setLoading(false);
            setErrorForm("");
            setPromoCodeError("");
          }
        },
        (error) => {
          const data = error?.response?.data;
          console.error(data);
          // console.error(error.response.data.errors.email);
          if (data?.errors?.email) {
            setErrorForm(data.errors.email);
          }else if(data?.errors?.promo_code){
            setPromoCodeError(data.errors.promo_code);
          }else{
            setErrorForm(data?.message ?? "Something went wrong.");
          }
          recaptchaRef.current.reset();
          clearTimeout(timer);
          setLoading(false);
        }
      );
  };

  const props = {
    generalMsg,
    setGeneralMsg,
  };

  return (
    <div className="signup-section">
      <Helmet>
        <title>Kino | Sign Up</title>
      </Helmet>
      <div className="signup-content">
        <Grid className="signup-container">
          <Grid container style={{minHeight:"750px"}} className="container-content">
          {/* <img src={img} /> */}
          <Grid md={6} sm={12} className="form-conatiner">
            <div className={`${!signUpWithMail ? "kino-logo-container" : "kino-logo-container-small"}`}>
              <img src={kinoImg} />
            </div>
            <div className="sign-up-content">
              {generalMsg ? (
              <div className={classes.errorGeneral}>{generalMsg}</div>
            ) : (
              ""
            )}
            {!signUpWithMail && <div>
            <h1 className={classes.mainTitle + " signupTitle"} id = 'mainTitle'>Inspiration starts here</h1>
            <p className={classes.mainDescr} id='mainDescr'>select your preferred sign-up method</p>
            <div className={classes.alternateAuth + " alternateAuth"}>
                {/* Sign up with google */}
                <div>
                  <div
                    className="auth-provider-sign-up-page google-login-sign-up-page authProviderSignup"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('google')
                      }
                      window.location.replace("/signin/google");
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="svg-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g>
                        <path
                          d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
                          fill="#EA4335"
                        ></path>
                      </g>
                    </svg>
                    {/* Sign up with Google */}
                  </div>
                </div>
                {/* Sign up with facebook */}
                <div>
                  <div
                    className="auth-provider-sign-up-page facebook-login-sign-up-page authProviderSignup"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('facebook')
                      }
                      window.location.replace("/signin/facebook");
                    }}
                    style={{borderColor: "#1877F2"}}
                  >
                    <svg
                      
                      width="534"
                      height="531"
                      viewBox="0 0 534 531"
                      fill="#1877F2"
                      
                    >
                      <path
                        d="M533.667 267C533.667 119.724 414.276 0.333344 267 0.333344C119.724 0.333344 0.333344 119.724 0.333344 267C0.333344 400.101 97.8494 510.422 225.333 530.427V344.083H157.625V267H225.333V208.25C225.333 141.417 265.145 104.5 326.057 104.5C355.233 104.5 385.75 109.708 385.75 109.708V175.333H352.124C318.997 175.333 308.667 195.889 308.667 216.978V267H382.625L370.802 344.083H308.667V530.427C436.151 510.422 533.667 400.101 533.667 267"
                        fill="#1877F2"
                      />
                    </svg>
                    {/* Sign up with Facebook */}
                  </div>
                </div>
                  <AppleAuth
                    {...rest}
                    {...props}
                    type={"signup"}
                    // buttonName="Sign up with Apple"
                    method={"signup"}
                    className={"sign-up"}
                  />
              </div>
              </div> }
              {<form 
                className="signup-form"    
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
                >
                <div className={classes.signupButtonContainer} style={{width: "100%"}}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label={errors.firstName ? errors.firstName.message : "First Name"}
                    error={errors.firstName && errors.firstName.message}
                    type="text"
                    variant="outlined"
                    inputProps={
                      { 
                        maxlength: 50, 
                        padding: 5, }
                    }
                    className={classes.textFieldSm}
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "First name is required",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Invalid first name",
                      },
                    })}
                    style={{marginRight: "10px"}}
                  />
                  <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label={errors.lastName ? errors.lastName.message  : "Last Name"}
                    error={errors.lastName && errors.lastName.message }
                    type="text"
                    variant="outlined"
                    inputProps={{ maxlength: 50 }}
                    className={classes.textFieldSm}
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "Last name is required",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Invalid last name",
                      },
                    })}
                  />
                </div>
                {/* <div className={classes.container}>
                  <div className={classes.textFieldCustom}>
                    {errors.firstName && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className={classes.textFieldCustom}>
                    {errors.lastName && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                </div> */}
                <TextField
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address"
                  // label={errors.email ? errors.email.message : "Email Address"}
                  error={errors.email && errors.email.message}
                  type="email"
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {/* {errors.email && (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errors.email.message}
                  </p>
                )}
                {errorForm ? (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errorForm}
                  </p>
                ) : (
                  ""
                )} */}
                <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  label={errors.password ? errors.password.message : "Password"}
                  error={errors.password && errors.password.message}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  inputRef={register({
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                      message:
                        "Password must include a combination of uppercase, lowercase letters and numbers.",
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {errors.password && (
                  <p className={classes.formError}>
                    {"⚠ "}
                    {errors.password.message}
                  </p>
                )} */}
                <TextField
                  margin="dense"
                  id="promoCode"
                  name="promoCode"
                  label="Promo Code (optional)"
                  // label={errors.promoCodeError ? promoCodeError : "Promo Code (optional)"}
                  error={errors.promoCodeError && promoCodeError}
                  type="text"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
                {promoCodeError && (
                  <p className={classes.formError}>{promoCodeError}</p>
                )}
                <div className={classes.ReCAPTCHA + ' captchaContainer'}>
                  <div className={classes.ReCAPTCHAContainer}>
                    {!rdd.isMobile ? (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaSiteKey}
                        onChange={(e) => handleRecaptcha(e)}
                        // onErrored={e => console.log(e)}
                        className="recaptcha"
                      />
                    ) : (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaSiteKey}
                        onChange={(e) => handleRecaptcha(e)}
                        // onErrored={e => console.log(e)}
                        className="recaptcha"
                      />
                    )}
                    {errors.recaptcha_token && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.recaptcha_token.message}
                      </p>
                    )}
                  </div>
                  {errorForm && (
                    <h4 className={classes.mainError} style={{marginBottom:"5px"}}>
                      {"⚠ "}
                      {errorForm}
                    </h4>
                    )}
                  <div className={classes.signupButtonContainer+ " signupButtonContainer"}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    style={{
                      marginTop: "0px"
                    }}
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    // disabled={!recaptchaValue}
                    type="submit"
                  >
                    {!loading && <span>SIGN UP</span>}
                    {loading && (
                      <div>
                        <CircularProgress
                          style={{
                            height: "17px",
                            width: "17px",
                            marginRight: "5px",
                            color: "#ffffff",
                            verticalAlign: "middle",
                          }}
                        />
                        &nbsp;Please wait
                      </div>
                    )}
                  </Button>
                </div>
                </div>
                <div>
              <p className="already-a-member">Already a member? <a href="/signin" className="sign-in-text">SIGN IN</a></p>
              
              </div>
              </form>
              }

            </div>
          </Grid>
          <Grid md={6} direction="column" className={`${classes.takeMeIn} take-me-in-container`}>
            <div>
            <h1 className={classes.takeMeInTitle + " takeMeInTitle"}>Already A Kino <br></br>Member?</h1>
            <h3 className={classes.takeMeInDescription + " takeMeInDescription"}>Sign in and continue to discover a <br></br> great amount of new opportunities!</h3>
            <a  href="/signin"
                style={{
                  textDecoration: "none",
                  color: "#ffff",
                  cursor: "pointer",
                }}>
            <Button variant="contained" type="submit" id="takeMeIn" className={classes.signInButton}>
           SIGN IN
            </Button></a>
            </div>
          </Grid>
          </Grid>
        </Grid>
        <p className={`${classes.termsDescriptions} termsDescriptionsMobile ${signUpWithMail ? "termsDescriptions" : " "}`}>
                By creating Kino account you accept Kino's{" "}
                <a href="/terms" className={classes.orangeTitle}>Terms & Conditions</a> and{" "}
                <a href="/privacy" className={classes.orangeTitle}>Privacy Policy</a>. By proceeding I confirm
                I am 18 years of age or older.
        </p>
      </div>
    </div>
  );
};

export default SignUp;

